import React from "react";
import "./home.css";
import Navbar from "../../components/navbar/navbar";
import Filter from "../../components/filter/filter";
import homeImg from "../../assets/home1.jpg";
import Footer from "../../components/footer/footer";

const Home = () => {
  return (
    <div className="home">
      <header>
      <Navbar />
      </header>
      <img src={homeImg} alt="Home Image"></img>

      <main className="home_description">
        <div className="home_description_head">
          <h4> Simplify Your Health Journey with</h4>
         
        </div>
        <div className="home_description_button"><h4>STD test Generator</h4> </div>
        <p>
          Explore a hassle-free way to generate and access standard STD tests
          tailored to official guidelines from trusted sources like the CDC,
          ensuring your peace of mind and well-being.
        </p>
      </main>

      <Filter />
      <footer>
      <Footer /></footer>
    </div>
  );
};

export default Home;
