/*
dropDown.js

This arrow function generates the drop down list button. 
Props 1 :  CallbackFunction 
Props 2 :  
Props 3 : Array of options for the drop down 
*/

import React, { useEffect, useState } from "react";
import "./dropdown.css";
const Dropdown = (props) => {
  const options = props.options;
  const { callback, category } = props;

  const DropdownHandleOnChange = (e) => {
    var filteredData = [category, e.target.value];
    callback(filteredData);
  };
  return (
    <div className="dropdown">
      <select
        name="Default Heading"
        defaultValue={"option0"}
        onChange={(e) => {
          DropdownHandleOnChange(e);
        }}
      >
        {options.map((item, index) => {
          return index === 0 ? (
            <option key={index} value={`option${index}`} defaultValue>
              {item}
            </option>
          ) : (
            <option name={index} key={index} value={`${item}`}>
              {item}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Dropdown;
