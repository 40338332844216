import axios from "axios" 
// export const DbConnection=()=>{
    

// }

export const GetData=()=>{
   // alert("im called")
    console.log("DBCONNECTION IS CALED")
    //
    return(axios.get("http://localhost:4000/stds_stis"))
    

}

export default GetData;

// .then((response)=>
//     (response.data)).catch((error)=>(console.log(error.name +"\n"+error.message)))