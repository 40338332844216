import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import {Helmet} from 'react-helmet';
function App() {
  return (
    <div className="App">
      {/* <header className="App-header"></header> */}
      <Router>
        <Helmet>
          <title>STD test Generator</title>
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path=""/>
          <Route path="/blog/std"/>
          <Route path="/blog/diabetics"/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
