//arrow function
//return div
import React from "react";
import "./footer.css";
import logo from "../../assets/logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
const Footer = () => {
  const products = [
    { name: "STD Tests", link: "aaaa" },
    { name: "STD Blog", link: "bbbb" },
    { name: "Diabetics Tests", link: "cccc" },
    { name: "Diabetics Blog", link: "dddd" },
  ];

  const company = [
    { name: "About Us ", link: "aaaa" },
    { name: "Portfolio", link: "bbbb" },
    { name: "Contact us ", link: "cccc" },
  ];

  return (
    <div className="footer">
      <div className="footer_content">
        <div className="footer_content_left">
          {/* <div className="footer_content_left_img"> */}
          <img src={logo}></img>
          {/* </div> */}

          <div className="footer_content_left_socialmedia">
            {<FacebookIcon  style={{ fontSize: 15 }} />}
            {<InstagramIcon style={{ fontSize: 15 }} />}
          </div>
        </div>
        {/* PRODUCTS */}
        <div className="footer_content_middle">
          <div className="footer_content_middle_head">Products </div>
          {products.map((item, key) => {
            return <div>{item.name}</div>;
          })}
        </div>
        {/* COMPANY */}
        <div className="footer_content_right">
          <div className="footer_content_right_head">Company </div>
          {company.map((item, key) => {
            return <div>{item.name}</div>;
          })}
        </div>
      </div>
      <div className="footer_copyrights">
        &copy; 2023 Lankan Health. All rights reserved. | Designed by tekkzen
      </div>
    </div>
  );
};

export default Footer;
