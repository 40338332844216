import React, { useEffect, useState } from "react";
import "./filter.css";
import Dropdown from "../dropdown/dropdown";
import TestGenerator from "../utilities/testGenerator/testGenerator";
import GetData from "../utilities/crud/CRUD";
const Filter = () => {
  const dropDownOptions = {
    ActivityOptions: ["Activity Type", "Gave", "Received"],
    protectionOptions: [
      "Protection status",
      "Protected",
      "Un-Protected",
      "Damaged/Broken Condom",
    ],
  };
  const initialData = {
    date: null,
    exposureType: {
      oralActivity: "Default",
      oralProtection: "Default",
      vaginalActivity: "Default",
      vaginalProtection: "Default",
      analActivity: "Default",
      analProtection: "Default",
    },
  };

  const [FilterData, setFilterData] = useState(initialData);
  const handleDropDownChange = (data) => {
    var exposureType = "exposureType";
    //e.preventDefault();

    setFilterData((prevData) => ({
      ...prevData,
      exposureType: {
        ...prevData.exposureType,
        [data[0]]: data[1],
      },
    }));
  };
  useEffect(() => {
    // console.log(FilterData);
    //console.log(FilterData.exposureType);
  }, [FilterData]);
  
  const handleChange=(e)=>{
    
    setFilterData((prevData)=>({...prevData, [e.target.name]:[e.target.value]}))
    // setFilterData((prevData)=>({   ...prevData,
    //   exposureType: {
    //     ...prevData.exposureType,
    //     [data[0]]: data[1],
    //   },}))
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(FilterData);
    //console.log("Handle submit has been clicked");
  };
const handleClick =(e)=>{
  e.preventDefault();
  TestGenerator()

}
  return (
    <div className="filter">
      <h2>EXPOSURE DETAILS</h2>
      <form className="filter_form">
        <label className="filter_form_exp">
          <h4>Last exposure date: </h4>
          <input onChange={(e)=>handleChange(e)} type="date" id="datepicker" name="date"></input>
        </label>
        {/* Test Button */}
        <label className="filter_form_exp">
          <h4>TEST BUTTON</h4>
          <button onClick={(e)=>handleClick(e)} >TESTBUTTON</button>
        </label>
        {/* Test Button End  */}
        <label className="filter_form_exp">
          <h3>Type Of Exposure </h3>
        </label>
        <label className="filter_form_exp">
          <div className="filter_form_exp_head">
            <h4> Oral</h4>
          </div>

          <div>
            <Dropdown
              callback={handleDropDownChange}
              options={dropDownOptions.ActivityOptions}
              category="oralActivity"
            />
          </div>
          <div>
            <Dropdown
              callback={handleDropDownChange}
              options={dropDownOptions.protectionOptions}
              category="oralProtection"
            />
          </div>
        </label>
        <label className="filter_form_exp">
          <div className="filter_form_exp_head">
            <h4>Vaginal</h4>
          </div>
          <div>
            <Dropdown
              callback={handleDropDownChange}
              options={dropDownOptions.ActivityOptions}
              category="vaginalActivity"
            />
          </div>
          <div>
            <Dropdown
              callback={handleDropDownChange}
              options={dropDownOptions.protectionOptions}
              category="vaginalProtection"
            />
          </div>
        </label>
        <label className="filter_form_exp">
          <div className="filter_form_exp_head">
            <h4>Anal</h4>
          </div>
          <div>
            <Dropdown
              callback={handleDropDownChange}
              options={dropDownOptions.ActivityOptions}
              category="analActivity"
            />
          </div>
          <div>
            <Dropdown
              callback={handleDropDownChange}
              options={dropDownOptions.protectionOptions}
              category="analProtection"
            />
          </div>
        </label>
        <button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Generate
        </button>
      </form>
    </div>
  );
};
export default Filter;
